<template>
  <div class="list-contact p-4">
    <div class="list-search">
      <input v-model="searchVal" placeholder="номер или имя">
    </div>
    <table class="table table-striped">
      <tr v-for="(item, idx) in formatedList" :key="idx">
        <td>{{ item[0] }}</td>
        <td>{{ item[1] }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: ['list'],
  name: "SendContactListModal",
  data() {
    return {
      visibleList: this.list,
      searchVal: null,
    }
  },
  watch: {
    searchVal: function (newVal, oldVal) {
      if (newVal) {
        const searchArray = this.list.map(item => item.join(','))
        this.visibleList = searchArray.filter(item => item.toLowerCase().search(newVal.toLowerCase()) >= 0).map(item => item.split(','))
        console.log(searchArray, oldVal, newVal, this.visibleList)
      }
    }
  },
  computed: {
    formatedList() {
      if (!this.searchVal) {
        return this.list
      }
      return this.visibleList
    }
  }
}
</script>

<style lang="scss" scoped>
.table-striped {
  tr:nth-child(odd) {
    background: #f5f5f5;
  }
}
.list-contact {
  height: inherit;
  overflow-y: auto;
}
.list-search {
  input {
    width: 100%;
    padding: 5px 10px;
    outline: none;
    border: 1px solid #eee;
  }
  padding: 10px 0px;
  position: relative;
  .clear__input {
    position: absolute;
    right: 0;
    top: 50%;
    width: 15px;
    height: 15px;
  }
}
</style>