<template>
  <div class="tab" v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Tab.vue",
  props: {
    title: {
      type: String,
      default: 'Tab',
    },
    selected: { default: false}
  },
  data () {
    return {
      isActive: false
    }
  },
  mounted() {
    this.isActive = this.selected;
  }
}
</script>

<style scoped>

</style>