<template>
  <div>
    <SmsSendForm
        v-if="allowed_phones.length"
        @submited="handleForm"
        :placeholders="placeholders"
        :send-variant="sendVariant"
        :allowed-phones="allowed_phones"
    />
  </div>
</template>

<script>
import SmsSendForm from "../../components/SmsSendForm";

export default {
  name: "SmsSendAdd",
  components: {SmsSendForm},
  data() {
    return {
      sendVariant: [
        // {text: 'Всем', value: 0},
        // {text: 'Ученикам', value: 1}
      ],
      placeholders: [],
      allowed_phones: []
    }
  },
  methods: {
    async handleForm(form) {
      await this.create(form)
    },
    async create(form) {
      try {
        let payload = {
          'message': form.message_text,
          'name': form.mailing_name,
          'type': form.send_variant,
          'send_phone': form.send_phone,
        }

        if (form.datePeriodType) {
          if (form.datePeriodType === 'dynamic') {
            payload['period'] = {
              type: form.datePeriodType,
              ...form.dynPeriod
            }
          } else if (form.datePeriodType === 'fixed') {
            payload['period'] = {
              type: form.datePeriodType,
              ...form.fixPeriod
            }
          }
        }

        if (form.sendType === 'file') {
          let temp = new FormData()
          for (const key in payload) {
            temp.append(key, payload[key])
          }
          temp.append('data_file', form.attachment)
          payload = temp;
        }

        console.log(form, payload)

        let {data: res} = await this.$axios.post('api/mailing/wa', payload)
        if (res.success) {
          return this.$router.push({name: "SmsSend"})
        }
      } catch (e) {
        console.log(e)
      }
    },
    createDropdownData(array, insertData, sameKeys = false) {
      for (const key in insertData) {
        array.push({
          text: insertData[key],
          value: sameKeys ? insertData[key] : key
        })
      }
    }
  },
  async created() {
    try {
      const {data: reqs} = await this.$axios.get('api/mailing/wa/create')
      const {placeholders, status_list, allowed_phones} = reqs;
      for (const key in placeholders) {
        this.placeholders.push(`{{ ${key} }} ${placeholders[key]['title']}`)
      }
      this.createDropdownData(this.sendVariant, status_list)
      this.createDropdownData(this.allowed_phones, allowed_phones, true)
    } catch (e) {
      console.log(e)
    }

  }
}
</script>

