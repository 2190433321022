<template>
  <b-form @submit.prevent="$emit('submited', form)">
    <b-form-input
        placeholder="Ваше название рассылки"
        v-model="form.mailing_name"
        autofocus
        required
        class="mb-1 mailing__name">
    </b-form-input>
    <div class="widget p-4">
      <div class="row">
        <div class="widget__toolbar">

        </div>
        <div class="d-flex flex-column col-6">
          <!--            <label for="sendtext">Текст сообщения</label>-->
          <textarea id="sendtext"
                    class="form-control sendtext__inp"
                    placeholder="Введите ваше сообщение"
                    required
                    v-model="form.message_text"
          ></textarea>
        </div>
        <div class="col-6">
          <div>
            <p class="text-black-50 m-0">Список шаблонов
              <a class="placeholder__helper">
                <i class="fa fa-question-circle" aria-hidden="true"></i>
                <span class="placeholder__description">
                    Шаблон представляет динамическу подстановку значений, например:
                    "Уважаемый <strong v-html="'{{ parent_name }}'"></strong> ..." <br>
                    Получатель увидит сообщение: <br>"Уважаемый <strong>Попов Иван Иванович</strong> ..."<br>
                    Каждый шаблон должен быть заключен в фигурные скобки <strong
                    v-html="'{{ название_шаблона }}'"></strong>, если в сообщении содержатся несколько шаблонов, то каждый шаблон нужно заключать в фигурные скобки
                  </span>
              </a>
            </p>
            <ul>
              <li v-for="(placeholder, idx) in placeholders" :key="idx">{{ placeholder }}</li>
            </ul>
          </div>

          <div class="contact__wrapper">
            <Tabs @tabSelected="tabSelected" :selected-tab="form.send_variant === 6 ? 1 : 0">
              <Tab title="Из существующих">
                <div class="contact__list">
                  <p class="text-black-50 m-0">Получатели:</p>
                  <p class="text-left">
                    <select class="form__dropdown" v-model="form.send_variant">
                      <option v-for="(item, idx) in sendVariant" :key="idx" :value="item.value">{{ item.text }}</option>
                    </select>
                  </p>

                  <div class="date__period" v-if="calendarAllowed">
                    <Tabs @tabSelected="periodSelected">
                      <Tab title="за период">
                        <div class="period__container">
                          <p>
                            <span>Тип</span>
                            <select v-model="form.dynPeriod.type" id="period__select">
                              <option value="month">Месяц</option>
                              <option value="week">Неделя</option>
                              <option value="day">День</option>
                            </select>
                            <span>Количество</span>
                            <input type="number" min="1" v-model="form.dynPeriod.value">
                          </p>
                        </div>
                      </Tab>
                      <Tab title="за конкретную дату">
                        <div class="period__container">
                          <DatePicker v-model="form.fixPeriod" is-range>
                            <template v-slot="{ inputValue, inputEvents }">
                              <label for="startDate" class="lbl">Начало</label>
                              <input
                                  id="startDate"
                                  class="bg-white border px-2 py-1"
                                  :value="inputValue.start"
                                  v-on="inputEvents.start"
                              />

                              <label for="endDate" class="lbl lbl_end">Конец</label>
                              <input
                                  id="endDate"
                                  class="bg-white border px-2 py-1"
                                  :value="inputValue.end"
                                  v-on="inputEvents.end"
                              />
                            </template>
                          </DatePicker>
                          <!--                          <DatePicker v-model="form.fixPeriod.dateEnd">-->
                          <!--                            <template v-slot="{ inputValue, inputEvents }">-->
                          <!--                              -->
                          <!--                            </template>-->
                          <!--                          </DatePicker>-->
                        </div>
                      </Tab>
                    </Tabs>


                  </div>
                </div>
              </Tab>
              <Tab title="Из списка">
                <div class="d-flex flex-row align-items-center">


                  <div v-if="xlsData && !form.attachment" class="mr-4">
                    <a href="#" class="xls-modal__link" @click="showXlsList">Показать список</a>
                  </div>
                  <div>
                    <p class="text-black-50 m-0">Контакты из файла xls / xlsx:</p>
                    <p class="text-left">
                      <input class="contact__file" type="file" accept=".xls,.xlsx" @change="fileUploaded">
                    </p>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          <p class="text-black-50 m-0">Номер для рассылки:</p>
          <p class="text-left">
            <select class="form__dropdown" v-model="form.send_phone" required>
              <option v-for="(item, idx) in allowedPhones" :key="idx" :value="item.value">{{ item.text }}</option>
            </select>
          </p>
          <div class="col-12 d-flex justify-content-end">
            <button type="submit" class="button_add" :disabled="blockAction">Сохранить</button>
            <button class="button_add button_back ml-3" @click.prevent="$router.back()">Назад</button>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import Tabs from "./Tabs/Tabs";
import Tab from "./Tabs/Tab";
import DatePicker from "v-calendar/lib/components/date-picker.umd"
import moment from "moment";
import SendContactListModal from "./Modal/SendContactListModal";

export default {
  name: "SmsSendForm",
  components: {Tabs, Tab, DatePicker},
  props: ['sendVariant', 'placeholders', 'clonedForm', 'blockAction', 'allowedPhones', 'xlsData'],
  data() {
    return {
      sendVariantCalendar: ['11', '21', "71"],
      form: {
        sendType: 'current',
        datePeriodType: null,
        message_text: null,
        mailing_name: null,
        send_variant: null,
        send_phone: null,
        attachment: null,
        dynPeriod: {
          type: null,
          value: null
        },
        fixPeriod: {
          start: null,
          end: null
        }
      },
    }
  },
  computed: {
    calendarAllowed() {
      return this.sendVariantCalendar.includes(this.form.send_variant)
    },
  },
  methods: {
    fillForm() {
      if (this.clonedForm) {
        const keys = Object.keys(this.clonedForm)
        for (const key of keys) {
          console.log('fill form', key, keys, this.form[key])
          this.form[key] = this.clonedForm[key];
        }
      }
    },
    fileUploaded(evt) {
      this.form.attachment = evt.target.files[0]
      console.log(evt, this.form.attachment)
    },
    tabSelected(tabData) {
      if (tabData.idx === 1) {
        this.form.sendType = 'file'
      } else {
        this.form.sendType = 'current'
      }
    },
    periodSelected(tabData) {
      if (tabData.idx === 1) {
        this.form.datePeriodType = 'fixed'
      } else {
        this.form.datePeriodType = 'dynamic'
      }
    },
    showXlsList() {
      this.$modal.show(
          SendContactListModal,
          {
            list: this.xlsData,
            deleteHandler: this.deleteRecord,
          }, {name: 'SendContactListModal', height: '700', scrollable: true}
      )
    },
  },
  watch: {
    clonedForm: function (val) {
      console.log('---- CLONED FORM ----', val);
      if (val) {
        this.fillForm()
      }
    }
  },
  created() {
    console.log('--------', this.clonedForm)
    if (this.clonedForm) {
      console.log('--------', this.clonedForm)
      this.fillForm()
    }
  },
  mounted() {
    this.form.send_phone = this.allowedPhones[0].value
  }
}
</script>

<style lang="scss" scoped>
.contact__wrapper {
  margin-bottom: 15px;
}

.lbl {
  width: 70px;

  &_end {
    margin-left: 10px;
  }
}

.mailing__name {
  background: #F9FBFD !important;
  border: none;
  box-shadow: none !important;
  font-size: 24px;
  font-weight: bold;
}

.button_add {
  //height: 30px;
  //float: right;
}

.button_back {
  background: #21AE8C;
}

.xls-modal__link {
  color: #fff;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  background: #3E86CA;
}

.sendtext__inp {
  flex: 1;
  resize: none;
}

.form__dropdown {
  padding: 5px;
  border-radius: 5px;
}

.placeholder__description {
  display: none;
  position: absolute;
  width: 500px;
  color: #000;
  font-size: 12px;
  background: #fff;
  padding: 10px;
  box-shadow: 0px 10px 20px rgba(#000, .1);
  top: 20px;
}

.placeholder__helper {
  position: relative;

  &:hover {
    .placeholder__description {
      display: block;
    }
  }
}

.contact__container {
  display: flex;
}

.contact__list {
  margin-right: 5px;
}
</style>