<template>
  <div class="tabs">
    <div class="tabs__header">
      <a
          v-for="(tab, idx) in tabs"
          :key="tab.title"
          class="tab__title"
          :class="{'active': idx === selectedIndex}"
          href="#"
          @click.prevent="selectTab(idx)">{{ tab.title }}</a>
    </div>
    <div class="tab__container">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  props: {
    selectedTab: {
      default: 0,
    },
  },
  data () {
    return {
      selectedIndex: 0, // the index of the selected tab,
      tabs: [],        // all of the tabs
    }
  },
  // watch: {
  //   selectedTab: function (newVal, oldVal) {
  //     this.selectTab(this.selectedTab)
  //   }
  // },
  created () {
    this.tabs = this.$children
  },
  mounted() {
    this.selectTab(this.selectedTab)
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
      this.$emit('tabSelected', {idx: i})
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs__header {
  display: flex;
}
.tabs {
  //border: 1px solid #eee;
}
.tab__title {
  flex: 1;
  text-align: center;
  padding: 5px 15px;
  background: #eee;
  &.active {
    background: #fff;
  }
}
.tab__container {
  padding: 15px 0;
  border-top: 1px solid #eee;
}
</style>